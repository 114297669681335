import { useState, useEffect } from 'react';
import { X, Mail, Lock, AlertCircle, CheckCircle2 } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStart: () => void;
}

type AuthMode = 'signin' | 'signup' | 'reset';

interface SuccessMessageProps {
  onAnimationComplete: () => void;
}

const SuccessMessage = ({ onAnimationComplete }: SuccessMessageProps) => {
  useEffect(() => {
    const timer = setTimeout(onAnimationComplete, 2000);
    return () => clearTimeout(timer);
  }, [onAnimationComplete]);

  return (
    <div className="flex flex-col items-center justify-center p-8 animate-fadeIn">
      <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4 animate-scaleIn">
        <CheckCircle2 className="w-8 h-8 text-green-600" />
      </div>
      <h2 className="text-xl font-semibold text-gray-900 mb-2 text-center">
        Account Created Successfully!
      </h2>
      <p className="text-gray-600 text-center">
        Please check your email to verify your account.
      </p>
    </div>
  );
};

export default function AuthModal({ isOpen, onClose, onStart }: AuthModalProps) {
  const [mode, setMode] = useState<AuthMode>('signup');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  
  const { signInWithEmail, signUpWithEmail, resetPassword, signInWithGoogle, user } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      if (mode === 'signin') {
        await signInWithEmail(email, password);
        toast.success('Welcome back!');
        onClose();
        setTimeout(() => {
          console.log('Calling onStart after signin');
          onStart();
        }, 100);
      } else if (mode === 'signup') {
        if (password !== confirmPassword) {
          setError('Passwords do not match');
          return;
        }
        await signUpWithEmail(email, password);
        setShowSuccess(true);
      } else if (mode === 'reset') {
        await resetPassword(email);
        toast.success('Password reset email sent!');
        setMode('signin');
      }
    } catch (error) {
      console.error('Auth error:', error);
      if (error instanceof Error) {
        if (error.message.includes('auth/email-already-in-use')) {
          setError('This email is already registered. Please sign in instead.');
        } else if (error.message.includes('auth/weak-password')) {
          setError('Password should be at least 6 characters long.');
        } else if (error.message.includes('auth/invalid-email')) {
          setError('Invalid email address.');
        } else if (error.message.includes('auth/user-not-found')) {
          setError('No account found with this email.');
        } else if (error.message.includes('auth/wrong-password')) {
          setError('Incorrect password.');
        } else {
          setError(error.message);
        }
      } else {
        setError('An error occurred during authentication');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      toast.success('Welcome! Successfully signed in with Google');
      onClose();
      setTimeout(() => {
        console.log('Calling onStart after Google signin');
        onStart();
      }, 100);
    } catch (error) {
      console.error('Google sign in error:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Failed to sign in with Google');
      }
    }
  };

  const handleSuccessComplete = () => {
    setShowSuccess(false);
    setMode('signin');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    onClose();
    setTimeout(() => {
      console.log('Calling onStart after signup success');
      onStart();
    }, 100);
    toast.success('Account created! Please check your email to verify your account.', {
      duration: 6000,
      position: 'top-center',
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-2xl max-w-md w-full mx-4 p-6">
        {showSuccess ? (
          <SuccessMessage onAnimationComplete={handleSuccessComplete} />
        ) : (
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">
                {mode === 'signin' ? 'Sign In' : mode === 'signup' ? 'Create Account' : 'Reset Password'}
              </h2>
              <button
                onClick={onClose}
                className="p-2 text-gray-400 hover:text-gray-600 rounded-lg transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg flex items-center gap-2">
                <AlertCircle className="w-5 h-5" />
                <span>{error}</span>
              </div>
            )}

            <div className="mb-6">
              <button
                onClick={handleGoogleSignIn}
                className="w-full py-3 px-4 bg-white hover:bg-gray-50 text-gray-800 font-medium rounded-lg border border-gray-300 shadow-sm hover:shadow transition-all flex items-center justify-center gap-3 relative"
              >
                <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5" />
                Sign in with Google
              </button>

              <div className="relative mt-6 mb-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">or continue with email</span>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email Address
                </label>
                <div className="relative">
                  <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
              </div>

              {mode !== 'reset' && (
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                    Password
                  </label>
                  <div className="relative">
                    <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      required
                    />
                  </div>
                </div>
              )}

              {mode === 'signup' && (
                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                    Confirm Password
                  </label>
                  <div className="relative">
                    <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                    <input
                      type="password"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      required
                    />
                  </div>
                </div>
              )}

              <button
                type="submit"
                className="w-full py-2 px-4 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
              >
                {mode === 'signin' ? 'Sign In' : mode === 'signup' ? 'Create Account' : 'Send Reset Link'}
              </button>
            </form>

            <div className="mt-6 text-center text-sm">
              {mode === 'signin' ? (
                <>
                  <button
                    onClick={() => setMode('reset')}
                    className="text-indigo-600 hover:text-indigo-700"
                  >
                    Forgot password?
                  </button>
                  <div className="mt-2">
                    Don't have an account?{' '}
                    <button
                      onClick={() => setMode('signup')}
                      className="text-indigo-600 hover:text-indigo-700"
                    >
                      Sign up
                    </button>
                  </div>
                </>
              ) : mode === 'signup' ? (
                <div>
                  Already have an account?{' '}
                  <button
                    onClick={() => setMode('signin')}
                    className="text-indigo-600 hover:text-indigo-700"
                  >
                    Sign in
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setMode('signin')}
                  className="text-indigo-600 hover:text-indigo-700"
                >
                  Back to sign in
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
} 