import React, { useCallback, useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { GripVertical, Trash2, Image, Upload, AlertCircle, Link as LinkIcon, ExternalLink } from 'lucide-react';
import { Block, StyleType } from '../types';
import toast from 'react-hot-toast';

interface EditableBlockProps {
  block: Block;
  index: number;
  onUpdate: (id: string, content: string, mediaUrl?: string) => void;
  onRemove: (id: string) => void;
  style: StyleType;
}

const getYouTubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

export default function EditableBlock({ block, index, onUpdate, onRemove, style }: EditableBlockProps) {
  const [isDraggingFile, setIsDraggingFile] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isMediaBlock = ['image', 'video', 'chart', 'graph'].includes(block.type);
  const isSocialBlock = ['twitter', 'facebook', 'instagram', 'youtube'].includes(block.type);

  const getPlaceholder = () => {
    switch (block.type) {
      case 'twitter': return 'Enter X (Twitter) post URL...';
      case 'facebook': return 'Enter Facebook post URL...';
      case 'instagram': return 'Enter Instagram post URL...';
      case 'youtube': return 'Enter YouTube video URL...';
      case 'image': return 'Drop image or enter URL...';
      case 'video': return 'Drop video or enter URL...';
      case 'chart': return 'Drop chart image or enter URL...';
      case 'graph': return 'Drop graph image or enter URL...';
      case 'link': return 'Enter website URL...';
      default: return 'Enter URL...';
    }
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    onUpdate(block.id, url);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value;
    const [url] = block.content.split('|');
    onUpdate(block.id, `${url}|${title}`);
  };

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDraggingFile(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDraggingFile(false);
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDraggingFile(false);

    const file = e.dataTransfer.files[0];
    if (!file) return;

    if (block.type === 'video' && !file.type.startsWith('video/')) {
      toast.error('Please drop a video file');
      return;
    }

    if (block.type !== 'video' && !file.type.startsWith('image/')) {
      toast.error('Please drop an image file');
      return;
    }

    if (file.size > 10 * 1024 * 1024) { // 10MB limit
      toast.error('File size must be less than 10MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        onUpdate(block.id, block.content, event.target.result as string);
        toast.success('File uploaded successfully');
      }
    };
    reader.readAsDataURL(file);
  }, [block.id, block.content, block.type, onUpdate]);

  const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (block.type === 'video' && !file.type.startsWith('video/')) {
      toast.error('Please select a video file');
      return;
    }

    if (block.type !== 'video' && !file.type.startsWith('image/')) {
      toast.error('Please select an image file');
      return;
    }

    if (file.size > 10 * 1024 * 1024) { // 10MB limit
      toast.error('File size must be less than 10MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        onUpdate(block.id, block.content, event.target.result as string);
        toast.success('File uploaded successfully');
      }
    };
    reader.readAsDataURL(file);
  }, [block.id, block.content, block.type, onUpdate]);

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const getTextareaProps = (value: string, onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void) => ({
    value,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e);
      adjustTextareaHeight(e.target);
    },
    onFocus: (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setIsFocused(true);
      e.target.style.minHeight = '150px';
    },
    onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setIsFocused(false);
      adjustTextareaHeight(e.target);
    },
    style: {
      minHeight: '80px',
      padding: '12px',
      lineHeight: '1.6',
    }
  });

  const getTextareaClass = (blockType: string) => {
    const baseClass = `w-full bg-transparent border-0 focus:ring-0 rounded-md transition-all duration-200 ${
      isFocused ? 'bg-blue-50/30' : ''
    }`;

    switch (blockType) {
      case 'headline':
        return `${baseClass} text-2xl font-bold ${
          style === 'classic'
            ? 'font-serif tracking-normal'
            : 'font-sans tracking-tight bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text'
        }`;
      case 'subheading':
        return `${baseClass} text-xl font-semibold text-gray-700 ${
          style === 'classic'
            ? 'font-serif tracking-normal'
            : 'font-sans tracking-tight'
        }`;
      case 'body':
        return `${baseClass} ${
          style === 'classic'
            ? 'font-serif tracking-normal leading-relaxed'
            : 'font-sans tracking-tight leading-relaxed'
        }`;
      case 'quote':
        return `${baseClass} pl-6 italic ${
          style === 'classic'
            ? 'font-serif tracking-normal'
            : 'font-sans tracking-tight'
        }`;
      case 'footer':
        return `${baseClass} text-sm text-gray-600 ${
          style === 'classic'
            ? 'font-serif tracking-normal'
            : 'font-sans tracking-tight'
        }`;
      default:
        return baseClass;
    }
  };

  const validateSocialUrl = (type: string, url: string) => {
    switch (type) {
      case 'twitter':
        return url.includes('twitter.com') || url.includes('x.com');
      case 'facebook':
        return url.includes('facebook.com');
      case 'instagram':
        return url.includes('instagram.com');
      case 'youtube':
        return url.includes('youtube.com') || url.includes('youtu.be');
      default:
        return true;
    }
  };

  const handleSocialUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    if (validateSocialUrl(block.type, url)) {
      onUpdate(block.id, url);
    }
  };

  const renderSocialPreview = () => {
    if (!block.content) return null;

    switch (block.type) {
      case 'twitter':
        return (
          <div className="mt-2 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 text-gray-600">
              <AlertCircle className="w-4 h-4" />
              <span className="text-sm">X (Twitter) post will appear here in preview mode</span>
            </div>
          </div>
        );

      case 'facebook':
        return (
          <div className="mt-2 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 text-gray-600">
              <AlertCircle className="w-4 h-4" />
              <span className="text-sm">Facebook post will appear here in preview mode</span>
            </div>
          </div>
        );

      case 'instagram':
        return (
          <div className="mt-2 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 text-gray-600">
              <AlertCircle className="w-4 h-4" />
              <span className="text-sm">Instagram post will appear here in preview mode</span>
            </div>
          </div>
        );

      case 'youtube':
        return block.content ? (
          <div className="mt-2 aspect-video">
            <iframe
              src={`https://www.youtube.com/embed/${getYouTubeId(block.content)}`}
              className="w-full h-full rounded-lg"
              allowFullScreen
              title="YouTube video"
            ></iframe>
          </div>
        ) : null;
    }
  };

  if (isSocialBlock) {
    return (
      <Draggable draggableId={block.id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`group relative bg-white rounded-lg border p-4 transition-all duration-200 cursor-grab active:cursor-grabbing ${
              isFocused
                ? 'border-blue-500 shadow-lg ring-1 ring-blue-500/20'
                : 'border-gray-200/50 shadow-sm hover:shadow-md'
            }`}
          >
            <div className="flex items-start gap-4">
              <div className="mt-2 p-1 rounded hover:bg-gray-100 text-gray-400 hover:text-gray-600 transition-colors">
                <GripVertical className="w-4 h-4" />
              </div>

              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <span className="text-xs font-medium px-2 py-1 rounded-full bg-gray-100 text-gray-600">
                      {block.type}
                    </span>
                  </div>
                  <button
                    onClick={() => onRemove(block.id)}
                    className="opacity-0 group-hover:opacity-100 p-1 rounded hover:bg-red-50 text-gray-400 hover:text-red-500 transition-all"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>

                <div className="flex-1">
                  <input
                    type="text"
                    value={block.content}
                    onChange={handleSocialUrlChange}
                    placeholder={getPlaceholder()}
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  {renderSocialPreview()}
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }

  return (
    <Draggable draggableId={block.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`group relative bg-white rounded-lg border p-4 transition-all duration-200 cursor-grab active:cursor-grabbing ${
            isDraggingFile 
              ? 'border-indigo-500 bg-indigo-50/50' 
              : isFocused
                ? 'border-blue-500 shadow-lg ring-1 ring-blue-500/20'
                : 'border-gray-200/50 shadow-sm hover:shadow-md'
          }`}
        >
          <div className="flex items-start gap-4 p-4">
            <div className="mt-2 p-1 rounded hover:bg-gray-100 text-gray-400 hover:text-gray-600 transition-colors">
              <GripVertical className="w-4 h-4" />
            </div>

            <div className="flex-1 min-w-0">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <span className="text-xs font-medium px-2 py-1 rounded-full bg-gray-100 text-gray-600">
                    {block.type}
                  </span>
                </div>
                <button
                  onClick={() => onRemove(block.id)}
                  className="opacity-0 group-hover:opacity-100 p-1 rounded hover:bg-red-50 text-gray-400 hover:text-red-500 transition-all"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>

              {block.type === 'contact' && (
                <div className="flex gap-4">
                  <div className="flex-1">
                    <textarea
                      {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value, block.mediaUrl))}
                      className={`w-full textarea-autosize bg-transparent border-0 focus:ring-0 font-mono text-sm ${
                        style === 'classic' 
                          ? 'font-serif tracking-normal'
                          : 'font-sans tracking-tight'
                      }`}
                      placeholder="Enter contact information..."
                    />
                  </div>
                  <div className="flex-shrink-0 w-32">
                    {block.mediaUrl ? (
                      <div className="relative group/image">
                        <img
                          src={block.mediaUrl}
                          alt="Logo"
                          className="w-32 h-32 object-contain rounded-lg border border-gray-200"
                        />
                        <button
                          onClick={() => onUpdate(block.id, block.content, '')}
                          className="absolute inset-0 bg-black/50 opacity-0 group-hover/image:opacity-100 transition-opacity flex items-center justify-center text-white rounded-lg"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    ) : (
                      <label className="flex flex-col items-center justify-center w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg hover:border-indigo-500 hover:bg-indigo-50/50 transition-colors cursor-pointer">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileSelect}
                          className="hidden"
                        />
                        <Image className="w-6 h-6 text-gray-400" />
                        <span className="mt-2 text-xs text-gray-500">Add logo</span>
                      </label>
                    )}
                  </div>
                </div>
              )}

              {block.type === 'headline' && (
                <textarea
                  {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value))}
                  className={getTextareaClass('headline')}
                  placeholder="Enter headline..."
                />
              )}

              {block.type === 'subheading' && (
                <textarea
                  value={block.content}
                  onChange={(e) => onUpdate(block.id, e.target.value)}
                  className={`w-full bg-transparent border-0 focus:ring-0 resize-none text-xl font-semibold text-gray-700 ${
                    style === 'classic'
                      ? 'font-serif tracking-normal'
                      : 'font-sans tracking-tight'
                  }`}
                  placeholder="Enter subheading..."
                />
              )}

              {block.type === 'body' && (
                <textarea
                  {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value))}
                  className={getTextareaClass('body')}
                  placeholder="Enter body text..."
                />
              )}

              {block.type === 'quote' && (
                <div className="relative">
                  <textarea
                    {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value))}
                    className={`w-full textarea-autosize bg-transparent border-0 focus:ring-0 pl-6 italic ${
                      style === 'classic'
                        ? 'font-serif tracking-normal'
                        : 'font-sans tracking-tight'
                    }`}
                    placeholder="Enter quote..."
                  />
                  <div className="absolute left-0 top-0 bottom-0 w-2 bg-indigo-500/10 rounded" />
                </div>
              )}

              {block.type === 'footer' && (
                <textarea
                  {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value))}
                  className={getTextareaClass('footer')}
                  placeholder="Enter footer text..."
                />
              )}

              {(isMediaBlock || isSocialBlock) && (
                <div className="flex flex-col gap-4">
                  {block.mediaUrl ? (
                    <div className="relative group/media">
                      {block.type === 'video' ? (
                        <video
                          src={block.mediaUrl}
                          controls
                          className="w-full rounded-lg border border-gray-200"
                        />
                      ) : (
                        <div className="space-y-2">
                          <img
                            src={block.mediaUrl}
                            alt={block.content || block.type}
                            className="w-full max-h-[400px] object-contain rounded-lg border border-gray-200"
                          />
                          <textarea
                            {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value, block.mediaUrl))}
                            className="w-full textarea-autosize bg-transparent border border-gray-200 rounded-md px-3 py-2 text-sm text-gray-600 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="Add a caption (press Enter to save)..."
                            rows={1}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                e.currentTarget.blur();
                              }
                            }}
                          />
                        </div>
                      )}
                      <button
                        onClick={() => onUpdate(block.id, block.content, '')}
                        className="absolute top-2 right-2 p-2 bg-white/90 rounded-full opacity-0 group-hover/media:opacity-100 transition-opacity hover:bg-red-50 text-gray-600 hover:text-red-500"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  ) : (
                    <div
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      className="relative group/upload"
                    >
                      <label className="flex flex-col items-center justify-center p-8 border-2 border-dashed border-gray-300 rounded-lg hover:border-indigo-500 transition-colors cursor-pointer bg-gray-50/50 hover:bg-indigo-50/50">
                        <input
                          type="file"
                          accept={block.type === 'video' ? 'video/*' : 'image/*'}
                          onChange={handleFileSelect}
                          className="hidden"
                        />
                        <div className="flex flex-col items-center gap-2 text-gray-500">
                          <Upload className="w-8 h-8" />
                          <div className="text-sm font-medium">
                            Drop {block.type} here or click to upload
                          </div>
                          <div className="text-xs">
                            {block.type === 'video' ? 'MP4, WebM, Ogg' : 'PNG, JPG, GIF up to 10MB'}
                          </div>
                        </div>
                      </label>
                      <textarea
                        {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value, block.mediaUrl))}
                        className="mt-2 w-full textarea-autosize bg-transparent border border-gray-200 rounded-md px-3 py-2 text-sm text-gray-600 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder={getPlaceholder()}
                        rows={1}
                      />
                    </div>
                  )}
                </div>
              )}

              {block.type === 'link' && (
                <div className="space-y-3">
                  <div className="flex flex-col gap-2">
                    <input
                      type="url"
                      value={block.content.split('|')[0] || ''}
                      onChange={handleLinkChange}
                      placeholder="Enter website URL (e.g., https://example.com)"
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    <input
                      type="text"
                      value={block.content.split('|')[1] || ''}
                      onChange={handleTitleChange}
                      placeholder="Enter link title (optional)"
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                  {block.content && isValidUrl(block.content.split('|')[0]) && (
                    <div className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg">
                      <LinkIcon className="w-4 h-4 text-gray-500" />
                      <a
                        href={block.content.split('|')[0]}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-indigo-600 hover:text-indigo-700 flex items-center gap-1"
                      >
                        {block.content.split('|')[1] || block.content.split('|')[0]}
                        <ExternalLink className="w-3 h-3" />
                      </a>
                    </div>
                  )}
                </div>
              )}

              {block.type === 'cta' && (
                <div className="relative">
                  <textarea
                    {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value))}
                    className={`w-full textarea-autosize bg-transparent border-0 focus:ring-0 resize-none text-lg font-medium text-indigo-600 ${
                      style === 'classic'
                        ? 'font-serif tracking-normal'
                        : 'font-sans tracking-tight'
                    }`}
                    placeholder="Enter call to action text..."
                  />
                </div>
              )}

              {block.type === 'bullet' && (
                <div className="flex items-start gap-2 pl-4">
                  <div className="mt-1.5">•</div>
                  <div className="flex-1">
                    <textarea
                      {...getTextareaProps(block.content, (e) => onUpdate(block.id, e.target.value))}
                      className={`${getTextareaClass('body')}`}
                      placeholder="Enter bullet point text..."
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}